import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { WavyBackground } from "./components/ui/Wavy";

import "./index.css";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import Home from "./components/Home/Home";
import Resume from "./components/Resume/Resume";
import { useScrollVisibility } from "./utils/useScrollVisibility";

function App() {
  useScrollVisibility();

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Yasin Demir - UX / UI / Front End Developer</title>
        </Helmet>
        <div className="bg w-full h-full fixed"></div>

        <Header />
        <WavyBackground>
          <div className="container mx-auto my-8 py-4 px-8 ">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/resume" element={<Resume />} />
            </Routes>
          </div>
        </WavyBackground>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
