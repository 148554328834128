import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const links = [
    { path: "/", name: "Home" },
    { path: "/about", name: "About" },
    { path: "/resume", name: "Resume" },
  ];

  return (
    <header className="header shadow-lg z-20 fixed w-full top-0" id="header">
      <div className="border-indigo-darkest bg-teal py-4 px-2">
        <div className="container mx-auto">
          <nav className="flex justify-between items-center">
            <div className="flex items-center flex-no-shrink">
              <NavLink to="/" className="flex items-center main-link">
                <img
                  className="mx-3.5 rounded-full border-2 border-opacity-50 image show-on-scroll"
                  src="../../images/yasindemir.jpg"
                  alt="Yasin Demir / UX / UI / Front End Developer"
                  style={{ width: "100px" }}
                />
                <span className="font-semibold text-3xl tracking-tight block hidden md:block show-on-scroll">
                  Yasin Demir
                </span>
              </NavLink>
            </div>
            <div id="mainNav" className="text-sm flex">
              {links.map((link, index) => (
                <NavLink
                  key={index}
                  to={link.path}
                  className={({ isActive }) =>
                    isActive
                      ? "nav__link text-xl font-bold block mt-8 sm:inline-block sm:mt-0 text-blue-600 border-b-2 border-blue-600 mr-4"
                      : "nav__link text-xl font-bold block mt-8 sm:inline-block sm:mt-0 hover:text-blue-600 mr-4"
                  }
                >
                  {link.name}
                </NavLink>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
