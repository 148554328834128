import { useEffect } from 'react';

export function useScrollVisibility() {
  useEffect(() => {
    const scroll = window.requestAnimationFrame || function(callback) {
      window.setTimeout(callback, 1000 / 60);
    };

    const elementsToShow = document.querySelectorAll('.show-on-scroll');

    function loop() {
      Array.prototype.forEach.call(elementsToShow, function(element) {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });

      scroll(loop);
    }

    function isElementInViewport(el: Element) {
      const rect = el.getBoundingClientRect();
      return (
        (rect.top <= 0 && rect.bottom >= 0) ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
        (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }

    loop();

    return () => {
      // Clean up the effect
      const elementsToShow = document.querySelectorAll('.show-on-scroll');
      elementsToShow.forEach(element => {
        element.classList.remove('is-visible');
      });
    };
  }, []);
}
