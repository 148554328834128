import React from "react";

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  return (
    <div className="resume text-left">
      <h1 className="text-4xl font-semibold text-blue-600 mb-8 show-on-scroll">
        About Me
      </h1>
      <p className="mb-8 text-2xl font-semibold show-on-scroll">
        I am a <strong>Senior UX/UI & Front-End Engineer</strong> with over a
        decade of experience in creating high-quality, scalable, and
        user-friendly web applications. I am currently based in Amsterdam and
        leading the Front-End development team at{" "}
        <a
          href="https://www.mkxnetwork.com/"
          target="_blank"
          className="text-blue-600 hover:text-white font-semibold"
        >
          MKX Network
        </a>
        .
      </p>
      <p className="mb-8 text-2xl font-semibold show-on-scroll">
        My expertise spans across modern JavaScript frameworks such as{" "}
        <strong>React JS, Vue JS, Next JS, Node JS, and Angular</strong>. I am
        passionate about creating intuitive and engaging user experiences,
        always ensuring performance, usability, and scalability in every
        project.
      </p>
      <p className="mb-8 text-2xl font-semibold show-on-scroll">
        Recently, I have been exploring the integration of{" "}
        <strong>AI-driven personalization</strong> into front-end development,
        leveraging technologies like TensorFlow.js to craft data-driven,
        intelligent user experiences. This allows me to combine my strong
        background in front-end engineering with cutting-edge AI solutions to
        deliver more personalized and engaging applications.
      </p>
      <p className="mb-8 text-2xl font-semibold show-on-scroll">
        Over the years, I have led the development of{" "}
        <strong>responsive, scalable web applications</strong> that ensure
        seamless performance across various devices. By focusing on performance
        optimization, user journey, and cross-browser compatibility, my work has
        contributed to improving user retention and satisfaction across
        different platforms.
      </p>
      <p className="mb-8 text-2xl font-semibold show-on-scroll">
        Outside of work, I am deeply interested in{" "}
        <strong>AI, machine learning</strong>, and how these technologies can be
        applied to enhance the web. I also enjoy <strong>photography</strong>,
        playing and watching football, and exploring new cultures while
        traveling.
      </p>
      <p className="mb-8 text-2xl font-semibold show-on-scroll">
        Feel free to contact me using the links below or explore my latest
        projects on GitHub and LinkedIn.
      </p>
    </div>
  );
};

export default About;
