import React from "react";

const Resume = () => {
  const employmentHistory = [
    {
      title: "UX/UI Front-End Team Lead",
      company: "MKX Network, Amsterdam, Netherlands",
      date: "February 2021 - Present",
      description: `
        Lead the front-end development team for a cutting-edge blockchain and financial technology platform, delivering high-performance, scalable solutions using React.js, Next.js, Angular, and TypeScript. Collaborated closely with blockchain developers to build intuitive, user-friendly interfaces for decentralized finance applications.
        Played a pivotal role in integrating AI-driven personalization features in 2023, optimizing user engagement and providing tailored user experiences based on behavioral analytics.
        Led the development of responsive web applications that support multi-device functionality, increasing user retention by 20% across mobile and desktop platforms. Utilized Angular for creating dynamic single-page applications (SPAs) that enhance user experience through seamless navigation and efficient data binding.
        Developed scalable, high-performance web applications using React.js, Next.js, Angular, and TypeScript, taking ownership of all front-end architecture as the team lead. Leveraged Angular’s powerful features, such as dependency injection and modular development, to improve code maintainability and application performance.
        Implemented state management using Redux and Context API to improve application performance and user experience.
        Focused on responsive web design and cross-browser compatibility, ensuring optimal performance across different devices.
        Developed reusable components and established a component-based architecture to accelerate project timelines and enhance code quality.
      `,
    },
    {
      title: "UX / UI / Frontend Developer",
      company: "Success Factory, Amsterdam, Netherlands",
      date: "February 2021 - September 2021",
      description: `
        Implemented state management using Redux, Context API, and Angular Services to improve application performance and user experience. Leveraged Angular’s dependency injection for better state management and modular development.
        Focused on responsive web design and cross-browser compatibility, ensuring optimal performance across different devices. Utilized Angular’s built-in responsive tools and enhanced device compatibility across the application.
        Developed reusable components using both React.js and Angular, establishing a component-based architecture to accelerate project timelines and enhance code quality. Ensured modular, scalable code in Angular through its hierarchical component structure.
      `,
    },
    {
      title: "Freelance UX/UI Front-End Developer",
      company: "Amsterdam",
      date: "November 2020 - February 2021",
      description: `
        Provided consultancy services with a focus on modernizing front-end stacks, leveraging React.js, Vue.js, Angular, and Vanilla.js to build scalable and maintainable applications.
        Developed custom hooks and reusable components in React.js, Vue.js, and Angular, reducing overall development time by 30% and ensuring rapid project delivery.
        Optimized front-end performance by implementing lazy loading, code splitting, and tree shaking techniques to reduce initial load times.
        Worked on cross-browser compatibility and responsive design, ensuring consistent user experiences across different devices and browsers.
        Utilized Vanilla.js for lightweight projects, enhancing performance where React.js, Vue.js, and Angular frameworks were not necessary.
        Collaborated with back-end developers to integrate APIs and improve the overall functionality of web applications.
      `,
    },
    {
      title: "UX/UI Front-End Development Product Architect",
      company: "Ciner Media Group, Istanbul, Turkey",
      date: "June 2015 - September 2020",
      description: `
        Led the design and front-end development for some of Turkey's largest media platforms, including Habertürk, Bloomberg HT, Show TV, and Habertürk TV, providing high-performance, scalable solutions for millions of daily users.
        Developed real-time news and financial data interfaces using Vanilla JavaScript and TypeScript, improving page load speeds by 30% and ensuring responsive design across all platforms.
        Collaborated with UX/UI designers to deliver engaging and intuitive layouts for high-traffic websites, focusing on accessibility (WAI) and cross-browser compatibility.
        Integrated custom video players and live streaming features for Show TV and Bloomberg HT, enhancing the user experience for both live and on-demand content.
        Optimized performance through techniques like lazy loading, code splitting, and tree shaking, resulting in 25% faster page load times during high traffic periods.
        Led the integration of state management solutions using Redux and Context API, improving application performance and user experience.
        Established a component-based architecture, developing reusable components that accelerated project timelines and improved overall code quality.
      `,
    },
    {
      title: "User Experience/UI & Front-End Development",
      company: "APLUS Energy Investment Consultancy, Istanbul, Turkey",
      date: "February 2015 - June 2015",
      description: `
        Led the front-end development for a variety of energy efficiency and sustainability projects, focusing on delivering user-friendly dashboards and reporting tools for energy management systems.
        Developed custom user interfaces for energy monitoring platforms, enabling real-time data visualization of energy consumption, performance, and efficiency metrics using JavaScript and Chart.js.
        Collaborated with data scientists and energy analysts to integrate complex energy usage datasets into easy-to-understand visual dashboards, helping users make informed decisions about energy consumption.
        Implemented performance optimizations and data caching techniques, ensuring that real-time data updates were delivered without compromising the platform’s speed and usability.
        Ensured responsive and cross-browser compatibility, allowing the energy monitoring platforms to be accessed seamlessly across mobile, tablet, and desktop devices.
      `,
    },
    {
      title: "Web Development & UX/UI Front-End Developer",
      company: "Turkcell Technology, Istanbul, Turkey",
      date: "June 2012 - October 2013",
      description: `
        Collaborated with project managers and analysts to build enterprise-level web applications for one of Turkey’s largest telecommunication companies. Played a critical role in the development of customer-facing portals and internal tools, ensuring a smooth and responsive user experience using JavaScript, jQuery, and CSS3.
      `,
    },
    {
      title: "Front End Web Development & Applications Development Specialist",
      company: "Hurriyet, Istanbul, Turkey",
      date: "April 2006 - November 2009",
      description: `
        Led the development of social media applications and various projects for Hurriyet.com.tr.
        Managed web portal architecture and development, ensuring integration with new technologies and applications.
      `,
    },
  ];

  const education = [
    {
      institution: "Anadolu University, Istanbul",
      date: "2006 - 2008",
      field: "Business Administration",
    },
    {
      institution: "Balikesir University, Balikesir, Turkey",
      date: "2003 - 2005",
      field: "Information Technology and Computer Programming",
    },
  ];

  const courses = [
    "AI and Machine Learning – Coursera (in progress, 2024)",
    "Introduction to TensorFlow.js – Udemy (in progress, 2024)",
    "Web Analytics: Techniques for Data-Driven Decision Making (in progress, 2024)",
    "Optimize Front-End Web Apps : AI, Performance and SEO",
    "Amazon AWS Courses - 2023",
    "User Experience Design at Turkcell",
    "The Ultimate React Course - Jonas Schmedtmann - Udemy",
    "Vue.js at Udemy",
    "Machine Learning & Data Science: The Complete Visual Guide",
  ];

  const volunteering = [
    {
      title: "Strategy Committee Member",
      organization: "UXistanbul Conference",
      date: "January 2018 - Present",
      description: `
        As a Strategy Committee Member, I worked closely with industry leaders to shape the direction of the UXistanbul Conference. This involved organizing discussions, moderating panels, and bringing the latest UX trends to the forefront, making the conference one of the most influential in the region.
        Collaborated with industry leaders and experts to organize discussions and workshops that promote the latest innovations in UX and front-end technologies.
      `,
    },
  ];

  return (
    <div className="about">
      <h1 className="text-4xl font-semibold text-blue-600 text-left mb-8 show-on-scroll">
        Resume
      </h1>
      <section className="text-left text-xl leading-8">
        <div>
          <p className="text-2xl font-semibold text-blue-600 my-5 show-on-scroll">
            Profile
          </p>
          <p className="my-5 show-on-scroll">
            I am a <strong>Senior UX/UI & Front-End Engineer</strong> with over
            a decade of experience in creating high-quality, user-friendly, and
            accessible web applications. My expertise spans across modern
            JavaScript frameworks such as{" "}
            <strong>
              React JS, Vue JS, Next JS, Node JS, Vanilla JS, Angular
            </strong>
            , and I am deeply passionate about crafting engaging, intuitive user
            interfaces.
          </p>
          <p className="my-5 show-on-scroll">
            In recent years, I have developed a strong interest in{" "}
            <strong>Artificial Intelligence (AI)</strong> and have integrated
            AI-driven features into front-end projects to create personalized,
            data-driven user experiences. My work in this area focuses on
            improving user engagement through intelligent user interfaces and
            delivering custom experiences based on behavioral insights.
          </p>
          <p className="my-5 show-on-scroll">
            I have worked extensively with cloud services such as{" "}
            <strong>AWS</strong> to create scalable, high-performance
            applications. Additionally, I have integrated modern JavaScript
            frameworks such as <strong>Angular</strong> into various projects to
            enhance user experience and maintainability.
          </p>
          <p className="my-5 show-on-scroll">
            Throughout my career, I have led the development of{" "}
            <strong>responsive, scalable web applications</strong> that ensure
            optimal performance across different devices. By focusing on
            performance optimization and seamless user interactions, I have
            helped improve user retention and satisfaction across various
            industries.
          </p>
          <p className="my-5 show-on-scroll">
            With a strong background in <strong>front-end technologies</strong>,
            my goal is to apply innovative solutions to create efficient,
            maintainable, and user-centric digital platforms. I am particularly
            interested in projects that involve{" "}
            <strong>user journey optimization</strong>,{" "}
            <strong>data visualization</strong>, and the application of modern
            front-end techniques to enhance digital experiences.
          </p>
        </div>
        <div className="my-10">
          <p className="text-2xl font-semibold text-blue-600 my-5 show-on-scroll">
            Employment History
          </p>
          {employmentHistory.map((item, index) => (
            <div key={index} className="my-10">
              <h2 className="text-1xl font-semibold show-on-scroll">
                {item.title}, {item.company}
              </h2>
              <small className="text-1xl font-semibold show-on-scroll">
                {item.date}
              </small>
              <p className="show-on-scroll">{item.description}</p>
            </div>
          ))}
        </div>
        <div className="show-on-scroll my-10">
          <p className="text-2xl font-semibold text-blue-600 my-5 show-on-scroll">
            Education
          </p>
          {education.map((item, index) => (
            <div key={index} className="my-10">
              <h2 className="text-1xl font-semibold show-on-scroll">
                {item.institution}
              </h2>
              <small className="text-1xl font-semibold show-on-scroll">
                {item.date}
              </small>
              <p className="show-on-scroll">{item.field}</p>
            </div>
          ))}
        </div>
        <div className="my-10">
          <p className="text-2xl font-semibold text-blue-600 my-5 show-on-scroll">
            Courses
          </p>
          {courses.map((course, index) => (
            <p key={index} className="show-on-scroll">
              {course}
            </p>
          ))}
        </div>
        <div className="my-10">
          <p className="text-2xl font-semibold text-blue-600 my-5 show-on-scroll">
            Volunteering Experience
          </p>
          {volunteering.map((item, index) => (
            <div key={index} className="my-10">
              <h2 className="text-1xl font-semibold show-on-scroll">
                {item.title}, {item.organization}
              </h2>
              <small className="text-1xl font-semibold show-on-scroll">
                {item.date}
              </small>
              <p className="show-on-scroll">{item.description}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Resume;
