import React from "react";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  return (
    <div className="home flex">
      <div className="flex justify-between flex-wrap w-full">
        <div className="flex1-50 text-left">
          <h1 className="text-4xl font-semibold my-2.5 text-blue-600 show-on-scroll">
            Hi, I'm Yasin.
          </h1>
          <h4 className="font-semibold text-2xl show-on-scroll">
            I am a <strong>Senior UX/UI/Front-End Engineer</strong>, passionate
            about React JS, Vue JS, Next JS, Node JS, Angular, AWS, and recently
            exploring AI technologies.
          </h4>
          <h2 className="font-semibold text-1xl my-2.5 show-on-scroll">
            <strong>Must:</strong> Interaction, Usability, Accessibility,
            Semantic, and AI-driven personalization.
          </h2>
          <ul className="list-outside md:list-inside text-left list-disc my-8 text-base">
            <li className="my-1.5 show-on-scroll">
              <strong>Responsive, Web-Development:</strong> HTML5, CSS, CSS3,
              Tailwind CSS, Storybook, Styled Components;
            </li>
            <li className="my-1.5 show-on-scroll">
              <strong>Front-end:</strong> Vanilla JavaScript, (ES6+),
              TypeScript, React, Angular, Vue, Next.js;
            </li>
            <li className="my-1.5 show-on-scroll">
              <strong>Cloud Services:</strong> AWS, PHP, ASP.NET, SQL;
            </li>
            <li className="my-1.5 show-on-scroll">
              <strong>Testing & Automation:</strong> Cypress, Jest;
            </li>
            <li className="my-1.5 show-on-scroll">
              <strong>Version Control & Tools:</strong> Git, GitLab, Scrum,
              Jira, Figma, Adobe XD;
            </li>
            <li className="my-1.5 show-on-scroll">
              <strong>AI Technologies:</strong> TensorFlow.js, AI-driven
              personalization;
            </li>
          </ul>
        </div>
        <div className="flex1-50">
          <div
            className="overflow-y-scroll overflow-x-hidden"
            style={{ height: "450px" }}
          >
            <h1 className="text-2xl font-semibold text-blue-600">
              Tweets by @thelifeisnth
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
